import axios from '@/config/httpConfig'
// 伙伴云初始化
export function savePartnerSetup(data) {
    return axios.post('/partnerSetup/savePartnerSetup', data)
}
// 检查域名可用性
export function checkPartnerFirst(data) {
    return axios.post('/partnerSetup/checkPartnerFirst', data)
}

// 查询伙伴云一级域名
export function getPartnerFirstUrl(data) {
    return axios.get('/partnerSetup/getPartnerFirstUrl', data)
}
// 启用禁用伙伴云工作区
export function savePartnerCommonSetup(data) {
    return axios.post('/partnerSetup/savePartnerCommonSetup', data)
}
// 查询伙伴云设置
export function queryPartnerSetup(data) {
    return axios.get('/partnerSetup/queryPartnerSetup', data)
}
