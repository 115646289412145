<template>
  <div class="bodyContent" v-loading="load">
    <!-- 未初始化 -->
    <div v-if="!isusing" class="unInit">
      <!-- intro -->
      <div class="intro">
        <!-- 普通信息 -->
        <!-- CloudCC支持您为不同类型的合作伙伴创建多个站点，分别配置登录页面、可用菜单、使用权限，满足公司的各种业务需求。例如，零售商可以为地方代理商、广告合作商创建不同的站点。 -->
        <!-- 编辑您的伙伴云站点唯一URL中的一部分。CloudCC.com提供URL的第一部分；后续部分只能包含数字或字母。建议使用贵公司的名称或贵公司名称的变型，如“mycpmpanypotal”。 -->
        <div>{{ $t("label.partnerCloud.basicSetting.intro") }}</div>
        <div>{{ $t("label.partnerCloud.basicSetting.introTwo") }}</div>
        <!-- 标红提示 -->
        <!-- 初始化后，您无法修改您的伙伴云域名。 -->
        <div style="color: #cc463d">
          {{ $t("label.partnerCloud.basicSetting.initialize") }}
        </div>
      </div>
      <!-- title   设置域名 -->
      <div class="title">
        {{ $t("label.partnerCloud.basicSetting.domainSet") }}
      </div>
      <!-- opreate -->
      <div class="opreate">
        <div>
          <!-- 域名配置 -->
          <div class="content">
            <!-- 域名文本 -->
            <div style="height: 30px; line-height: 30px">{{ baseUrl }}</div>
            <!-- 输入框以及域名验证提示 -->
            <div>
              <!-- 输入框 -->
              <el-input
                @keyup.enter.native="availabilityCheck"
                v-model="input"
              ></el-input>
              <!-- 提示 -->
              <div
                style="margin-top: 5px"
                :style="{
                  color: trueUrl ? '#06844B' : '#cc463d',
                }"
                v-show="showRemind"
              >
                {{ remind }}
              </div>
            </div>
            <!-- 按钮 -->
            <!-- 检查可用性 -->
            <el-button type="primary" size="small" @click="availabilityCheck">{{
              $t("label.partner.setup.Enable")
            }}</el-button>
          </div>
          <!-- 按钮 -->
        </div>
        <!-- 初始化按钮 -->
        <!-- 初始化伙伴云 -->
        <div class="positionBtn">
          <div class="btn" @click="initPartner">
            {{ $t("label.partnerCloud.basicSetting.cshua") }}
          </div>
        </div>
      </div>
      <!-- 确认初始化提醒 -->
      <!-- 提示 -->
      <el-dialog
        :visible.sync="dialogVisible"
        :title="$t('label.emailtocloudcc.tip')"
        top="15%"
        width="430px"
        height="215px"
        :close-on-click-modal="false"
        :before-close="handleClose"
      >
        <div style="font-size: 14px; color: #080707; margin: 20px 0px">
          <!-- 该操作不可逆，且初始化后不可修改域名，是否确定？ -->
          {{ $t("label.partnerCloud.basicSetting.domainName") }}
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="mini">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button @click="confirm" type="primary" size="mini">
            <!-- 确定 -->
            {{ $t("label.confirm") }}
          </el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 初始化 -->
    <div v-else style="position: relative; height: 100%">
      <el-dialog
        :visible.sync="secondDialogVisible"
        :title="$t('label.emailtocloudcc.tip')"
        top="15%"
        width="430px"
        height="215px"
        :close-on-click-modal="false"
        :before-close="handleCloseSecond"
      >
        <div style="font-size: 14px; color: #080707; margin: 20px 0px">
          <!-- 你将关闭伙伴云启用状态，系统将自动禁用贵组织伙伴云登录与新建修改功能！ -->
          {{ $t("label.partnerCloud.basicSetting.enable") }}
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="secondDialogVisible = false" size="mini">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button @click="secondConfirm" type="primary" size="mini">
            <!-- 确定 -->
            {{ $t("label.confirm") }}
          </el-button>
        </span>
      </el-dialog>
      <!-- 是否启用 -->
      <div class="isOpen">
        <!-- 启用伙伴云 -->
        <el-checkbox v-model="ischeck">{{
          $t("label_partner_common_enable")
        }}</el-checkbox>
      </div>
      <!-- 域名展示 -->
      <div>
        <!-- title -->
        <!-- 域名 -->
        <div class="urlTitle">{{ $t("label.partner.common.webSit") }}</div>
        <!-- 域名展示区域 -->
        <div class="urlArea">{{ baseUrl }}</div>
      </div>
      <!-- 角色和用户设置 -->
      <div>
        <!-- title -->
        <!-- 角色和用户设置 -->
        <div class="urlTitle">
          {{ $t("label.partnerCloud.basicSetting.userSetting") }}
        </div>
        <!-- 设置的内容 -->
        <div class="settingContent">
          <!-- 首行 -->
          <div class="firstRow">
            <!-- 合作伙伴角色数量 -->
            <div>{{ $t("label.partnerCloud.basicSetting.userCounts") }}</div>
            <!-- 添加合作伙伴客户时创建的默认角色数量 -->
            <el-tooltip
              popper-class="my-tooltip"
              class="item"
              effect="dark"
              :content="$t('label.partnerCloud.basicSetting.defaultCounts')"
              placement="top"
            >
              <div class="el-icon-warning"></div>
            </el-tooltip>
            <!-- 请选择 -->
            <el-select
              v-model="roleCount"
              :placeholder="$t('label.emailtocloudcc.select1')"
            >
              <el-option
                v-for="item in roleMenu"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 第二行 -->
          <div style="margin-top: 15px">
            <!-- 允许伙伴云用户编辑个人信息 -->
            <el-checkbox v-model="editable">{{
              $t("label.partnerCloud.basicSetting.editPersonal")
            }}</el-checkbox>
          </div>
        </div>
      </div>
      <!-- 功能设置 -->
      <div style="display: none">
        <!-- title -->
        <!-- 功能设置 -->
        <div class="urlTitle">
          {{ $t("label.partnerCloud.basicSetting.feature") }}
        </div>
        <!-- setting Area -->
        <div class="settingContent">
          <div
            class="checkboxPosition"
            v-for="(item, index) in settingMenu"
            :key="index"
          >
            <el-checkbox v-model="item.checked">{{ item.label }}</el-checkbox>
          </div>
        </div>
      </div>
      <!-- 底部按钮 -->
      <div class="btnGroup">
        <el-button
          @click="confirmSave"
          style="margin-left: 10px"
          type="primary"
          size="mini"
        >
          <!-- 保存 -->
          {{ $t("label.save") }}
        </el-button>
        <el-button @click="cancel" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  savePartnerSetup,
  checkPartnerFirst,
  getPartnerFirstUrl,
  savePartnerCommonSetup,
  queryPartnerSetup,
} from "./api";
export default {
  data() {
    return {
      trueUrl: false,
      load: false,
      isusing: false,
      ischeck: true,
      baseUrl: "",
      remind: "",
      dialogVisible: false,
      secondDialogVisible: false,
      input: "",
      showRemind: false,
      roleCount: 1,
      roleMenu: [{ value: "1" }, { value: "2" }],
      editable: false,
      settingMenu: [
        {
          checked: true,
          label: this.$i18n.t("label.partnerCloud.basicSetting.offline"),
        }, //启用线下支付
        {
          checked: true,
          label: this.$i18n.t("label.partnerCloud.basicSetting.online"),
        }, //启用线上支付
        {
          checked: true,
          label: this.$i18n.t("label.partnerCloud.basicSetting.partnerPayment"),
        }, //启用伙伴账户
        {
          checked: true,
          label: this.$i18n.t("label.partnerCloud.basicSetting.rebate"),
        }, //启用返利
      ],
    };
  },
  async mounted() {
    this.getBasicUrl();
    let result = await queryPartnerSetup();
    result = result.data;
    this.isusing = result.isusing === "true" ? true : false;
    this.ischeck = result.partnerworkspace === "true" ? true : false;
    this.roleCount = result.rolenum;
    this.editable = result.isedituser === "true" ? true : false;
    this.settingMenu[0].checked =
      result.isOfflinepayments === "true" ? true : false;
    this.settingMenu[1].checked =
      result.isOnlinepayment === "true" ? true : false;
    this.settingMenu[2].checked =
      result.isPartneraccount === "true" ? true : false;
    this.settingMenu[3].checked = result.isRebate === "true" ? true : false;
  },
  created() {},

  methods: {
    async cancel() {
      // 启用伙伴云
      let result = await queryPartnerSetup();
      this.ischeck = result.data.partnerworkspace === "true" ? true : false;
      // 一级域名
      this.getBasicUrl();
      // 设置
      this.roleCount = result.data.rolenum;
      this.editable = result.data.isedituser === "true" ? true : false;
    },
    secondConfirm() {
      this.load = true;
      this.saveSetting(true);
    },
    // 确认保存
    confirmSave() {
      if (this.ischeck == false) {
        this.secondDialogVisible = true;
      } else {
        this.saveSetting(false);
      }
    },
    // 打开加载提示
    showLoading() {
      this.load = true;
    },
    // 关闭加载提示
    hideLoading() {
      this.load = false;
    },
    // 保存伙伴云设置
    
    async saveSetting(type) {
      let obj = {
        partnerworkspace: this.ischeck.toString(),
        rolenum: this.roleCount,
        isedituser: this.editable.toString(),
      };
      await this.showLoading();
      let result = await savePartnerCommonSetup(obj);
      await this.hideLoading();
      if (result.returnCode == "1") {
        if (type) {
          this.secondDialogVisible = false;
        }
        // 保存成功
        this.$message({
          message: this.$i18n.t("savesuccess"),
          type: "success",
        });
      }
    },
    async getBasicUrl() {
      let result = await getPartnerFirstUrl();
      this.baseUrl = result.data.parterfirstUrl;
    },
    // 确定初始化
    async confirm() {
      this.dialogVisible = false;
      // 调接口 初始化保存  再提示初始化成功
      let obj = {
        first: this.input,
      };
      await this.showLoading();
      let result = await savePartnerSetup(obj);
      await this.hideLoading();
      if (result.returnCode == "1") {
        // 初始化成功
        this.$message({
          message: this.$i18n.t("label.partnerCloud.basicSetting.success"),
          type: "success",
        });
        await this.getBasicUrl();
        let newResult = await queryPartnerSetup();
        let result = newResult.data;
        this.isusing = result.isusing === "true" ? true : false;
        this.ischeck = result.partnerworkspace === "true" ? true : false;
        this.roleCount = result.rolenum;
        this.editable = result.isedituser === "true" ? true : false;
        this.settingMenu[0].checked =
          result.isOfflinepayments === "true" ? true : false;
        this.settingMenu[1].checked =
          result.isOnlinepayment === "true" ? true : false;
        this.settingMenu[2].checked =
          result.isPartneraccount === "true" ? true : false;
        this.settingMenu[3].checked = result.isRebate === "true" ? true : false;
        this.isusing = true;
      }
    },
    // 关闭提示框
    handleClose() {
      this.dialogVisible = false;
    },
    // 关闭伙伴云未启用提示
    handleCloseSecond() {
      this.secondDialogVisible = false;
    },
    // 初始化伙伴云
    async initPartner() {
      // 先验证
      let result = await this.availabilityCheck();
      if (result.returnCode == "1") {
        this.dialogVisible = true;
      }
    },
    // 检测可用性
    async availabilityCheck() {
      // 一级域名不能为空
      if (this.input == "") {
        this.showRemind = true;
        this.remind = this.$i18n.t("label.partnerCloud.basicSetting.domain");
        this.trueUrl = false;
        let result = { returnCode: "2" };
        return result;
      } else {
        let result = await checkPartnerFirst({ domain: this.input });
        this.showRemind = true;
        // 如果是成功的
        // 域名可用
        if (result.returnCode == "1") {
          this.remind = this.$i18n.t(
            "label.partnerCloud.basicSetting.available"
          );
          this.trueUrl = true;
        } else {
          this.remind = result.data;
          this.trueUrl = false;
        }
        return result;
      }
    },
  },
  components: {},
};
</script>




<style lang="scss" scoped>
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #005fb2;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #005fb2;
  border-color: #005fb2;
}
.opreate {
  position: relative;
}
.positionBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn {
  background: #006dcc;
  border-radius: 3px;
  width: 176px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  color: #ffffff;
  position: absolute;
  left: calc(50% - 88px);
  top: 300px;
}
::v-deep .el-input__inner {
  width: 200px;
  height: 30px;
  padding: 0 15px;
}
::v-deep .el-button {
  width: 90px;
  height: 30px;
}
.bodyContent {
  height: 100%;
  // border: 1px solid #DDDBDA;
}
.intro {
  padding: 20px 10px;
  font-size: 12px;
  color: #080707;
}
.intro > div {
  line-height: 20px;
}
.title {
  background: #f3f2f2;
  height: 34px;
  line-height: 34px;
  padding-left: 10px;
}
.content {
  display: flex;
  padding: 14px 30px;
  // align-items: center;
}
.content > div {
  margin-right: 10px;
}
.isOpen {
  padding: 20px 30px;
}
.urlTitle {
  width: 100%;
  height: 34px;
  line-height: 34px;
  padding: 0px 10px;
  background: #f3f2f2;
}
.urlArea {
  font-size: 12px;
  height: 50px;
  line-height: 50px;
  padding: 0px 30px;
}
.settingContent {
  padding: 10px 30px;
}
.firstRow {
  display: flex;
  align-items: center;
}
.firstRow > div {
  margin-right: 5px;
}
.firstRow > div:nth-child(2) {
  width: 14px;
  height: 14px;
}
::v-deep .el-icon-warning:before {
  color: #888888;
}
::v-deep .el-input__inner {
  cursor: pointer;
  height: 30px;
}
::v-deep .el-input__suffix {
  top: 0px !important;
}
::v-deep .el-input__icon {
  line-height: 30px;
}
.checkboxPosition {
  margin-bottom: 15px;
}
.btnGroup {
  position: absolute;
  top: calc(100% - 60px);
  left: 0px;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row-reverse;
  border-top: 1px solid #dddbda;
  align-items: center;
  padding-right: 36px;
}
</style>
